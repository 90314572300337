const storeId = process.env.NEXT_PUBLIC_STORE_ID || 187;
const config = {
    baseURL: process.env.NEXT_PUBLIC_API_URL + "/" + process.env.NEXT_PUBLIC_PROXY_SERVER_PREFIX,
    connectURL: 'https://www.healthkart.com',
    AppURL: process.env.NEXT_PUBLIC_HOST_URL,
    storeId: process.env.NEXT_PUBLIC_STORE_ID || 187,
    OLD_VDAS: process.env.NEXT_PUBLIC_OLD_VDAS === "true",
    adobeId: "10669",
    googlsAdsConversionIdSuper: "AW-981492453",
    googlsAdsConversionIdMB: "AW-737686426",
    RAZOR_PAY_ENABLED: process.env.NEXT_PUBLIC_HK_RAZOR_PAY_ENABLED && process.env.NEXT_PUBLIC_HK_RAZOR_PAY_ENABLED.toString().toLowerCase() === "true" ? true : false,
    // MAILBOLT:{
    //     mailboltBaseUrl: process.env.NEXT_PUBLIC_MAILBOLT_URL,
    //     MAIL_BOLT_CONTACT_US_EMAIL: "/HKAdminTools/mail/contactUsEmail",
    //     HK_CONTACT_EMAIL_ADDRESS : process.env.NEXT_PUBLIC_HK_CONTACT_EMAIL_ADDRESS
    // },
    LOYALITY_ROLES: {
        NORMAL_USER: 'NORMAL',
        SELECT_USER: 'SELECT',
        PREMIUM_USER: 'PREMIUM'
    },
    APIS: {
        errorTracking: '/crm/hkswift/hkBrowserErrorTracking/save',
        //hapticSearch: '/search/haptikProductSearch',
        home: {
            firstFold: '/page/home/firstFold/' + storeId,
            secondFold: '/page/home/secondFold/' + storeId,
        },
        footer: {
            countOfBoxMuscleBlaze: '/variant/countOfBox/MuscleBlaze'
        },
        labResult: '/productVariant/paginated/labReport',
        viewDietPlanPageUrl: '/suggest/viewDietPlan/',
        downloadDietPlan: '/suggest/downloadDietPlan/',
        suggestproduct: '/suggest/product?plt=1&st=' + storeId,
        searchDefault: '/search/getUserResults/' + storeId,
        searchDefaultAfterLogin: '/search/v3/getUserResults/' + storeId + '/USER_ID',
        clearRecentSearchData: '/search/v3/clearSearchResults',
        searchWithParam: '/ac/compSorted',
        searchResult: '/search/results',
        searchResultV2: '/search/v2/results',
        searchResultV4: '/search/v4/results',
        pageData: '/page/data/' + storeId + '/',
        sendUserLoc: '/persona/record/user/location',
        eventTrack: '/event/track',
        V3EventTrack: '/event/v3/track',
        saveRecentSearch: '/search/options/saveRecentSearch',
        brandPageData: '/brand/page/data',
        brandPageDetails: '/brand/page/brandDetails',
        retailRefill: '/user/forceLogin/placeOrderForRefill/',
        authenticateWeb: '/auth/product',
        retailSms: '/retail/sms/',
        compareSearchUrl: '/ac/searchCompare',
        compareAssistanceUrl: '/variant/compareAttributes',
        variantsInfo: '/variant/mul/basic/id/compare?svIdList=',
        reviewSliderData: '/catalog/topRatedWidget/',
        catRatingReviewCount: '/catalog/avgRating/',
        catRatingReviewBrandCount: '/catalog/resultsAvg/',
        oldVariantDetail: '/variant/oldVariantDetail/OLD_VARIANT_ID/' + storeId,
        chatStatusCheck: '/crm/hkswift/storeConfigValuesWeb/get?query=%7B"query":[],"limit":1000,"offset":0,"sortBy":%7B"keyName":"createDt","value":-1%7D%7D',
        userFlow: {
            loginPassword: '/user/login',
            sendOTP: '/user/login/send/otp/' + storeId + '/',
            verifyOTP: '/user/login/otp/verify',
            socialLogin: '/user/socialLogin',
            userSignup: '/user/validate/' + storeId + '/PHONENUMBER/signup',
            userExist: '/user/' + storeId + '/userExist/EMAIL',
            userSignupVerify: '/user/signup/verify',
            forgetPassword: '/user/login/send/otp/' + storeId + '/PHONENUMBER',
            mergeSuggestion: '/user/v3/get/merge/suggestions/' + storeId,
            mergeAccount: '/user/v3/merge',
            socialLoginMergeCall: '/user/v3/merge/social',
            forgetOtp: '/user/otp/validate',
            validateOtp: '/user/otp/validate',
            changePassword: '/user/resetPassword/otp/verify',
            forgetByEmail: '/user/send/forgotPassword/mail',
            userDetails: '/user/v3/id',
            tempUserCreate: '/user/create/temp/' + storeId,
            guestUserCreate: '/user/guest/' + storeId,
            // logout: '/do/logout',
            logout: '/user/v3/logout',
            socialLoginOTP: '/user/send/otp/' + storeId + '/USERID/PHONENUMBER',
            forceLoginUser: '/user/forceLogin/validate/',
            deleteUser:'/user/v3/delete',
            referEarnPage:`/tb/userCoupon/v3/${storeId}/referAndEarn/details`,
            referEarnTotPoint: `/tb/userCoupon/v3/${storeId}/referAndEarn/availablePoint`
        },
        myAccount: {
            accountData: '/user/v3/id',
            updateBasicInfo: '/user/account/v3/basic/update',
            updatePassword: '/user/account/v3/password/update/',
            updateEmail: '/user/account/v3/email/update/',
            userHkCash: '/userPoints/reward/v3/' + storeId + '/results',
            reOrder: `/order/v3/reorder/${storeId}/USER_ID`,
            myOrder: '/order/v3/recent/' + storeId,
            trackOrder: '/order/v3/oprLi/status/OPRLI_ID/' + storeId + '/GID',
            deliveryInfo: '/order/v3/deliveryInfo/ITEM_ID/' + storeId + '/ORDER_ID',
            cancelMyOrder: '/order/v3/cancelMyAccount',
            invoicePageUrl: '/order/invoice?gatewayOrderId=GATEWAY_ORDER_ID',
            getInvoice: '/order/v3/USER_ID/' + storeId + '/GATEWAY_ORDER_ID',
            getAwbBasedInvoice: '/order/invoice?shipment_tracking_number=GATEWAY_ORDER_ID',
            getAWBInvoice: '/order/v3/invoice/GATEWAY_ORDER_ID',
            savedPayment: '/payment/v3/get/savedPayments',
            deleteCards: '/payment/v3/delete/card',
            getShipsyStatus: '/order/trackOrder/ORDER_ID',
            paymentPendingRecentOrder: '/order/v3/recent/pending/' + storeId,
            retryPaymentPending: '/order/v3/retryPending/' + storeId,
            reOrderwidget:  '/widgetData/v3/reorder/results'
        },
        wishlist: {
            add: '/wishlist/v3/add',
            delete: '/wishlist/v3/delete',
            get: '/wishlist/v3/getWishList/USER_ID/IS_CART'
        },
        address: {
            addressList: '/user/address/v3/get/' + storeId + '/list',
            emailUpdate: '/user/account/v3/email/update',
            createAddress: '/user/address/v3/create/',
            locationPincode: '/location/pincode/PINCODE/details',
            getStates: '/location/country/code/IN/states',
            updateAddress: '/user/address/v3/update/',
            deleteAddress: '/user/address/v3/delete/' + storeId + '/ADDRESS_ID',
            getAddress: '/user/address/v3/get/' + storeId + '/ADDRESS_ID'
        },
        category: {
            categoryData: '/page/data/' + storeId + '/NAV_KEY',
            catalogResults: '/catalog/results/NAV_KEY',
            categoryFilter: '/catalog/results'
        },
        captcha: {
            getCaptcha: '/get/captcha',
            verifyCaptcha: '/verify/captcha'
        },
        pdp: {
            productData: '/variant/product/PRODUCT_ID',
            variantData: '/variant/VARIANT_ID',
            variantFirstNew: '/variant/firstFold/VARIANT_ID',
            vdasNew: '/variant/variantsFromSvVDA/VARIANT_ID',
            productDataFirst: '/variant/firstFold/product/PRODUCT_ID',
            variantDatarem: '/variant/firstFold/remaining/VARIANT_ID',
            productDatarem: '/variant/firstFold/product/remaining/PRODUCT_ID',
            packData: '/pack/PACK_ID/data',
            comboPack: '/pack/VARIANT_ID/results',
            notifyMe: '/enquiry/notifyMe/update',
            starRating: '/variant/review/rate',
            revVote: '/variant/review/v3/voteOnAReview/',
            getBestPrice: '/variant/getBestPrice/VARIANT_ID',
            variendInfo: '/variant/mb/variantInfo/VARIANT_ID',
            productInfo: '/variant/mb/product/variantInfo/PRODUCT_ID',
            knowFlvQuestions: '/form/data/v3/get/9/20',
            knowFlvSaveAns: '/form/data/v3/save/userFormAnswer',
            getRemaining: '/variant/firstFold/remaining/VARIANT_ID',
            recommendedProduct: '/variant/recommendVariants/9?dayInterval=30',
            successApi: "/form/data/v3/save/userAnswer",
            ExtraDiscount: '/payment/v3/issuerOfferWithAdditionalCriteria/'+ storeId,
        },
        pinCode: {
            variantPincode: '/variant/PINCODE/variant/OLD_VARIANT_ID',
            addList: '/user/address/v3/getLatestPincodeList/' + storeId,
            selAdd: '/user/address/v3/selected',
            getPincode: '/tat/getPincode',
            getTatData: '/variant/tat/PINCODE/variant/OLD_VARIANT_ID',
            getTatDataForCombo: '/variant/tat/variantList'
        },
        compareVariant: {
            allVairant: '/variant/product/category/CAT_PREFIX/' + storeId,
            addVariant: '/variant/compare/add',
            removeVariant: '/variant/compare/remove',
            removeAllVariant: '/variant/compare/remove/all'
        },
        flashMenuDeal: '/menu/deal/' + storeId,
        variantLang: '/variant/languages/variants',
        reviewListing: {
            pageData: '/page/reviews/' + storeId + '?naKey=NAV_KEY',
            reviewResults: '/catalog/results/catalogReviews'
        },
        cart: {
            summary: '/cart/v3/summary/' + storeId,
            headerSummary: '/header/v3/summary/' + storeId,
            cartMerge: '/user/v3/login',
            addVariant: '/cart/v3/add',
            addPack: '/cart/v3/add/pack',
            buyPack: '/cart/v3/expressCheckout/pack',
            buyNow: '/cart/v3/expressCheckout',
            cartGet: '/cart/v3/get/' + storeId,
            redeemHKCash: '/cart/v3/redeem/' + storeId + '/POINT',
            applyOffer: '/cart/v3/applyOffer/' + storeId + '/OFFER_ID',
            applyCoupn: '/cart/v3/applyCoupon/' + storeId + '/COUPON_CODE',
            removeOffer: '/cart/v3/removeOffer/' + storeId,
            cartUpdate: '/cart/v3/update/',
            cartPackUpdate: '/cart/v3/update/pack',
            cartRemove: '/cart/v3/remove',
            cartPackRemove: '/cart/v3/remove/pack',
            applyBxgyOffer: '/cart/v3/updateOffer',
            removeBxgyOffer: '/cart/v3/removeOffer/' + storeId,
            multiVariants: '/cart/v3/add/multiVariants',
            biggerPack: '/cart/v3/availVarForFilteredVariants?amount=',
        },
        menu: {
            headerMenu: '/menu/' + storeId,
            menuCategory: '/page/home/firstFold/' + storeId,
            sideMenuCategory: '/page/data/' + storeId + '/hkLoyaltyBenefitsThirdPartyCoupon?thirdPartyCouponCompanyPrefix=menu'
        },
        payment: {
            preparePayment: '/payment/v3/' + storeId + '/prepare?adrsId=ADDRESS_ID&payTp=PAYMENT_TYPE_ID',
            paymentType: '/payment/v3/PAYMENT_TYPE_ID/nativePayment',
            paymentMode: '/payment/v3/paymentModes/',
            paymentCOD: '/payment/v3/PAYMENT_TYPE_ID/nativePayment',
            paymentRetail: '/payment/v3/PAYMENT_TYPE_ID/nativePayment/retail',
            createPayment: '/payment/v3/create',
            nativeAcceptPayment: '/payment/v3/' + storeId + '/USER_ID/accept/native',
            acceptPayment: '/payment/v3/accept',
            freeCheckout: '/payment/v3/freeCheckout',
            checkPlStatus: '/payment/v3/' + storeId + '/checkPLStatus',
            paymentModeRevamp: '/payment/v3/paymentModes/revamp',
            imageShow: "/iin/?cardNumber=CARD_NUMBER",
            cardOfferForCard: '/payment/v3/cardOfferForCard',
            securePayRequest: '/rest/api/payment/request/v2',
            cancelPendingPayment: '/order/v3/cancelPending/' + storeId,
            pollingPendingPaymentStatus: '/order/v3/pending/' + storeId + '/ORDER_ID'
        },
        trafficRequest: '/traffic/record',
        widgets: {
            conditional: {
                get: '/form/data/get/' + storeId + '/9',
                set: '/form/data/v3/save/userAnswer'
            }
        },
        // videoCall: '/mbconnect/api/videos/connect.json',
        //blogCall: '/mbconnect/api/transformation-blog/connect.json',
        shopByCategoryMenu: '/menu/get',
        subscribeNow: '/subscription/v2/update',
        subscribeNowLoggedIn: '/subscription/v3/update',
        subscriptionDetails: '/subscription/v3/get/details',
        //postQue: '/mbconnect/api/question.json',
        iaCall: '/catalogItem/mul/id/ia?svIdList=',
        variantReview: '/variant/review/VARIANT_ID/results',
        WidgetData: {
            typeA: '/widgetData/results?widgetName=',
            typeB: '/widgetData/justLaunched/retailStore/STORE_ID'
        },
        assistanceData: '/variant/getInfoTagVariants/TAG_ID',
        locateStore: '/user/nearestStore/detail',
        sale: '/sale/NAV_KEY/results/' + storeId,
        review: {
            viewAllData: '/variant/review/REVIEW_SLUG/results/allReviews?pageNo=PAGE_NO&perPage=10&storeId=STORE_ID',
            addFeatureRating: '/variant/review/add/feature/rating',
            createReview: '/variant/review/v3/create',
            rate: '/variant/review/rate',
            getRating: '/variant/review/getRating/' + storeId + '/userRating/USER_ID/SV_ID',
            addImage: '/multipart/variant/review/v3/add/image?st=STORE_ID&plt=PLATFORM_ID',
            imageRestriction: '/variant/review/imageRestrictions',
            getAllImages: '/variant/review/SV_ID/images',
            getReview: '/variant/review/RVW_ID/',
            getReviewImages: '/variant/review/RVW_ID/images',
            wordCloud: '/variant/review/word-cloud',
            wordCloudReviews: '/variant/review/word-cloud-reviews',
        },
        truebasics: {
            pointsHistory: '/tb/points/v3/history',
            userCoupon: '/tb/userCoupon/v3/details',
            concerns: '/tb/concerns',
            categories: '/tb/tbsCategories',
            ingredients: '/tb/tbsIngredients',
            stories: '/tb/tbsStories',
            categoryUrl: '/tb/category',
            categoryProducts: '/tb/category/results?tags=',
            catWidgets: '/catalogWidget/results?tags=',
            redeemPoints: '/tb/points/toBeRedeemed',
            pdpPageData: '/api/page/pagedatav1/STOREID/NAVKEY',
            hkPDPFirstFoldVariant: '/variant/firstFold/VARIANT_ID',
            hkPDPFirstFoldProduct: '/variant/firstFold/product/SP_ID',
            search: '/tb/results?txtQ=',
            ingredient: '/tb/ingredient/',
            reviewEligibility: '/tb/reviewEligibility'
        },
        crazydeals: '/catalog/results',
        RetailStoreStatus: '/retail/store/sdd/status/ORDER_ID',
        allCoupon: '/page/data/' + storeId + '/coupons',
        couponCat: '/page/data/' + storeId + '/couponCategory?catPrefix=CAT_NAVKEY',
        store: {
            getAll: '/retail/store/get/all',
            getByCity: '/retail/store/city/CITY_NAME',
            // getStoreDetail: '/retail/store/CITY_NAME/STORE_NAME',
            getStoreData: '/retail/store/storeInfo/CITY_NAME/STORE_NAME',
            getStoreDetail: '/retail/store/STORE_ID',
            storePageData: "/page/data/1/STORE_ID",
            storeSearchCall: "/search/results",
            postReview: "/retail/store/review/post",
            updateVisit: "/retail/store/updateVisit",
            storeJustLaunched: "/widgetData/justLaunched/retailStore/STORE_ID",
            sendLocationToMobile: "/retail/store/sendLocLink/STORE_ID"
        },
        chatauth: '/chat/jwt',
        loyalty: {
            genericPageSections: `/page/home/hkLoyalty/${storeId}?userId=USER_ID`,
            loyaltyLevelDetails: '/hkUserLoyalty/getLoyaltyLevelDetails?userId=USER_ID',
            rewardPageData: `/page/data/${storeId}/hkLoyaltyRewards?userId=USER_ID`,
            loyaltyLandingPage: `/page/data/${storeId}/Loyalty`,
            loyaltyPageData: `/page/data/${storeId}/hkLoyaltyRewards?userId=USER_ID`
        },
        bestSellers: {
            pageData: '/page/best-seller/' + storeId,
            variantResults: '/catalog/best-seller/results'
        },
        shop: {
            pageData: 'shop',
        },
        affiliates: {
            recordTraffic: '/recordTraffic',
            recordConversion: '/recordConversion'
        },
        crm: {
            gritzoAskExpert: '/crm/gritzo/askExpert',
            getKidList: '/crm/member/get',
            addMember: '/crm/member/addDetail',
            createMember: '/multipart/crm/member/create',
            updateMember: '/multipart/crm/member/update',
            removeMember: '/crm/member/remove',
            getMemberDetail: '/crm/member/getDetail',
            getWhoGraphData: '/crm/gritzo/getSpecificData?id=GRAPH_CELL&type=GRAPH_TYPE',
            removeMemberDetail: '/crm/member/removeDetail',
            userDashboardData: '/crm/fitness/userDashboardData',
            userProfileDetails: '/crm/fitness/userProfileDetails',
            getProductRecommendation: '/crm/bot/getProductRecommendation',
            saveUserRecommendationData: '/crm/fitness/saveUserData',
            personalized: '//crm/hkswift/gritzoForm/get?query={"query":[],"limit":1000,"offset":0,"sortBy":{"keyName":"createDt","value":-1}}',
            recommendedProductsGritzo: '//crm/hkswift/gritzoRecommended/get?query={"query":[],"limit":1000,"offset":0,"sortBy":{"keyName":"createDt","value":-1}}',
            reportErrorToDashboard: '/crm/hkswift/errortrackingui/save'
        },
        survey: {
            userExist: '/user/' + storeId + '/userExistMBForm/MOBILE',
            getUserDetails: '/user/v3/get/detail/' + storeId + '/MOBILE',
            getQuestions: '/form/data/get/' + storeId + '/17',
            updateInfo: '/user/v3/MB/info/update',
            saveAnswer: '/form/data/v3/save/userAnswer',
            rateData: '/form/data/get/' + storeId + '/30',
            successForm: '/form/data/get/' + storeId + '/29'
        },
        form: {
            oelp: '/form/data/get/' + storeId + '/30',
            fuelOne: '/form/data/get/' + storeId + '/35',
            sgoelp: '/form/data/get/' + storeId + '/36',
            contact: '/form/data/get/' + storeId + '/37',
            chat: '/form/data/get/' + storeId + '/40',
            oelpSave: '/form/data/v3/save/userFormAnswer'
        },
        feedback :'/feedback/create',
    },
    slider: {
        getSlidesNo: function (data, length, alternateLength) {
            if (!data || data.length === 0) return length
            return data.length > length ? alternateLength : length
        },

        responsiveSettings: function (data) {
            return [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: this.getSlidesNo(data, 2, 2.5),
                        slidesToScroll: this.getSlidesNo(data, 2, 2.5)
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: this.getSlidesNo(data, 1, 1.5),
                        slidesToScroll: this.getSlidesNo(data, 1, 1.5)
                    }
                }
            ]
        }
    },
    pageDataShorthands: {
        'home': 'HM',
        'category': 'CT'
    },
    persona: {
        existingPageTypes: {
            home: 'HM',
            storeVariant: 'PDP',
            pack: 'PDP',
            menuLanding: 'CAT',
            gbjProfile: 'HKCR',
            cart: 'CART',
            account: 'MYA',
            phoneNumberVerification: 'MYA',
            paymentSuccess: 'ORD',
            clearance: 'CAT',
            brandCatalog: 'CAT',
            search: 'CAT',
            category: 'CAT',
            consultHome: 'CONS',
            shop: 'SHOP',
            FlashSale: 'FLASHSALE',
            dealOffer: 'DEALOFFER',
            brand: 'BRAND',
            tbsCat: 'TBS-CAT',
            tbsIng: 'TBS-ING',
            tbsIdp: 'TBS-IDP'
        }
    },
    assistance_Tags: [
        {
            tagId: 1,
            tagName: "Whey Protein Concentrate"
        },
        {
            tagId: 2,
            tagName: "Whey Protein Isolate"
        },
        {
            tagId: 3,
            tagName: "Whey Protein Hydro"
        },
        {
            tagId: 4,
            tagName: "Protein Blends"
        },
        {
            tagId: 5,
            tagName: "Casein Proteins"
        },
        {
            tagId: 6,
            tagName: "Soy Proteins"
        }
    ],
    const: {
        GTM_CONT_ID: process.env.NEXT_PUBLIC_GTM_CONT_ID || 'GTM-XXXXXXX',
        GTAG_CONT_ID: process.env.NEXT_PUBLIC_GTAG_CONT_ID || 'UA-XXXXXXXX-XX',
        FB_API_KEY: process.env.NEXT_PUBLIC_FB_APP_ID || 876684205846395,
        GPLUS_API_KEY: process.env.NEXT_PUBLIC_GPLUS_APP_ID || '1022891210800-q5t6ijlkhovb6bqlic7055r9cudo1lik.apps.googleusercontent.com',
        EMAIL_REGEX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        PHONE_NO_REGEX: /^[0-9]+$/,
        MOB_NUM: /^[0-9]{10}$/,
        VISA_REGEX: new RegExp('^4[0-9]{0,15}'),
        MASTER_REGEX: new RegExp('^5[1-5][0-9]{0,14}'),
        AMEX_REGEX: new RegExp('^3[4|7][0-9]{0,14}'),
        MASTERO_REGEX: new RegExp('^(5018|5020|5038|5893|6304|6759|6761|6762|6762)[0-9]{0,12}'),
        RUPAY_REGEX: new RegExp('^(3528|(353[0-3])|3536|3538|(354[0-3])|3549|(355[0-3])|3560|(356[2-9])|(357[1-4])|3577|(358[0-8])|(360[0-5])|(360[7-9])|(361[0-9])|(362[0-6])|3628|3630|(363[3-5])|3637|3639|(364[0-9])|(365[0-5])|(366[0-9])|(367[0-1])|3683|3686|(369[0-2])|(369[4-6])|(508[5-9])|6011|6013|6014|6029|(603[2-3])|(603[6-7])|6069|(607[0-9])|(608[0-4])|(620[0-2])|6205|6208|6210|(621[2-9])|(622[1-9])|(623[0-6])|(624[3-6])|(625[0-9])|(626[2-4])|6270|(628[2-4])|(629[1-2])|6320|(64[4-9][0-9])|(65[0-9][0-9])|6858|6907|8152|(817[2-4])|8201|()900[1-3]|9059|9111|9400|9551|9555|9558|9559|9666|9688|9843|9900|9988|9999)[0-9]{0,12}'),
        ALPHABET_REGEX: new RegExp('[A-Za-z ]'),
        NAME_REGEX: new RegExp('^[A-Za-z ]+$'),
        NUMERIC_REGEX: new RegExp('^([1-9][0-9]*)$'),
        // cards regex
        // payment modes
        SAVE_CARD_ID: 5,
        SAVED_CARD_ID: 3001,
        SAVED_UPI: 3004,
        CREDIT_CARD_ID: 10,
        DEBIT_CARD_ID: 20,
        INTERNET_BANKING_ID: 30,
        EMI_ID: 60,
        WALLET_ID: 70,
        UPI_ID: 1900,
        UPI_GRID_ID: [1900, 1020],
        COD_ID: 999,
        CASH_PAYMENT_TYPE_ID: 'PG999',
        CARD_PAYMENT_TYPE_ID: 'PG9999',
        CASH_PT_ID: 40,
        CARD_PT_ID: 160,
        ONLINE_PAYMENT: 1000,
        HK_ACCOUNT_ID: process.env.NEXT_PUBLIC_HK_ACCOUNT_ID,
        PAY_AT_STORE_ID: 1200,
        // payment type
        FREE_CHECKOUT_PT: 5,
        PAY_AT_STORE_PT: 170,
        SENTRY_DNS: 'https://74a516d0fc7343e7b0aeac949278f93a@o396539.ingest.sentry.io/5249988',
        SENTRY_VALID_ERROS: ['Could not send OTP. Please try again later.', 'Something went wrong',],
        AUTHTOKEN_KEY: 'edf31',
        CVV_TYPE_FOUR: ['american express', 'diners club'],
        OFFER_CARD_TYPE: ['cc', 'dc', 'both', 'prepaid'],
        NETWORK_TYPE:{
            DINERS_CLUB: 'diners club',
            AMERICAN_EXPRESS: 'american express',
            VISA:'visa',
            RUPAY:'rupay',
            MASTERCARD:'mastercard',
            MAESTRO:'maestro',
        },
    },
    walletIds: [1010, 1020, 1050, 1060, 1090, 1110, 1120, 1130, 1140, 1150, 1160],
    homePageWidget: {
        designType: {
            gridCard: 27,
            shopByGoal: 2,
            shopByConcern: 3,
            shopByUsage: 4,
            subscription: 5,
            referandEarn: 6,
            testiMonials: 7,
            store: 8,
            shopByCategory: 9,
            TrendingNowIa: 10,
            newArrivalsIa: 11,
            bestSellerIa: 12,
            recentlyViewIa: 13,
            youMayAlsoLike: 14,
            consultConnect: 15,
            askAnExpert: 16,
            video: 17,
            questionAnswer: 18,
            earnHkCash: 19,
            appDownload: 20,
            allYouNeed: 21,
            bannerSideImage: 22,
            categoryCarousel: 25,
        },
        type: {
            carousel: 1,
            bannerDefault: 2,
            bannerHorizontalList: 3,
            productTiles: 4,
            iconTiles: 5,
            textOnly: 6,
            verticalContentWidget: 7,
            verticalContentTextWidget: 8,
            horizontalContentWidget: 9,
            horizontalContentTextWidget: 10,
            offerSlot: 14,
            whatsNewTile: 15,
            variantHeaders: 16,
            textWithBanner: 17,
            sectionWith_0_Item: 18,
            flashDealHome: 19,
            hkBrandPage: 22,
        }
    },
    iaContainers: {
        home: {
            trendingNowWidget: 0,
            newArrivalsWidget: 1,
        },
        storeVariant: {
            recentlyViewedWidget: 0,
            YMALWidget: 1,
            similarProductsWidget: 2,
        }
    },
    pageTypes: {
        trackOrder:'Track Order',
        plp: {
            category: 'category',
            goals: 'goals',
            menuLanding: 'menuLanding',
            brandCatalog: 'brandCatalog',
            search: 'search',
            FlashSale: 'FlashSale',
            bxgyOffers: 'bxgyOffers',
            freebieOffers: 'freebieOffers',
        },
        viewDietPlan: 'viewDietPlan',
        downloadDietPlan: 'downloadDietPlan',
        faq: 'faqs',
        cart: 'cart',
        checkout: 'checkout',
        coupon: "coupon",
        ContactUs: "contact_us",
        address: {
            selectAddress: 'selectAddress'
        },
        payment: {
            selectPayment: 'selectPayment'
        },
        orderSuccess: 'paymentSuccess',
        campaign: {
            retailRefill: 'retailRefill',
            storeManagerFeedback: 'rsmfb'
        },
        authenticity: 'authenticity',
        sale: 'clearance',
        trueBasic: {
            tbReferrals: "tb-referrals",
            myProfile: "my-profile",
            //  referralsHistory: "referrals-history",
            myReferrals: "my-referrals",
            tbHome: "home",
            referAndEarn: "referandearn",
            tbsProducts: "tbsProducts",
            tbsCategory: "tbsCategory",
            tbsCare: "tbsCare",
            MBCash: 'mbCash',
            tbsIngredients: "tbsIngredients",
            tbsSearch: "tbsSearch",
            tbsHonesty: "tbsHonesty",
            tbsStories: "tbsStories",
            tbsIdp: 'tbsIdp',
            tbsQuality: 'tbsQuality'
        },
        invoice: "order-invoice",
        login: "login",
        gritzo: {
            growthTrack: "growthTrack"
        },
        aboutUs: 'aboutUs',
        blogs: 'article',
        articleDetails: 'article',
        privacy: 'privacypolicy',
        tnc: 'termsAndCondition',
        videos: 'videos',
        gainwithmb: 'gainwithmb',
        mbDataCapture: 'Mb Data Capture',
        birthday: 'birthday',
        oelp: 'oelp',
        Fuel_One_Landing: 'fuel_one_landing',
        FUEL_ONE_SPORTS: 'fuel-one-sports',
        procheck: 'procheck',
        lwl: 'lwl',
        IronTherapy: 'IronTherapy',
        appChat: 'appChat',
        ourStory: 'ourStory',
        offers: 'offers',
        Personalization: 'Personalization',
    },
    CONSTANT_STRINGS: {
        TNC_TITLE: "Read offer's terms and conditions",
        PLACE_ORDER_SUCCESS_MESSAGE: "Thanks for confirmation, Our store Manager will call you shortly.",
        FEEDBACK_MESSAGE: "Thanks for your feedback.",
        COMPARE_DIFF_CAT_ERROR: "This products belongs to different category from items present in compare. Only same category products can be compared.",
    },
    CAT_TYPES: {
        truebasics: {
            defaultCatArray: [
                "ReferAndEarn",
                "BrandStory"
            ],
            catQueryString: "&srtBy=RANK&srtType=ASC&excludeOOS=true&primVrntOnly=true&perPage=12",
            catQueryStringCatPages: "&srtBy=RANK&srtType=ASC&excludeOOS=true&primVrntOnly=false&perPage=12"
        }
    },
    PAGE_SECTION_TYPE: {
        BANNERS_DEFAULT: 2,
        SHOP_BY_CATEGORY_WIDGET: 14,
        NEW_RELEASES_WIDGET: 16,
        CRAZY_DEALS_DEAL_OFFER_WIDGET: 30,
        WIPE_OUT_SALE_DEAL_OFFER_WIDGET: 31,
        HALF_PRICE_STORE_DEAL_OFFER_WIDGET: 32,
        UPCOMING_DEALS_DEAL_OFFER_WIDGET: 33,
        BX_GY_DEAL_OFFER_WIDGET: 34,
        FREEBIES_DEAL_OFFER_WIDGET: 35,
        BANNERS_DEFAULT_WITH_TERMS_AND_CONDITION: 36,
        PICK_THE_BEST_FOR_YOU_DEAL_OFFER_WIDGET: 37,
        FLASH_SALE_DEAL_OFFER_WIDGET: 38,
        TOP_OFFERS_ON_BRANDS_WIDGET: 41,
        MOST_POPULAR_BRANDS_WIDGET: 42,
        MEET_YOUR_GOALS_WIDGET: 43,
        TB_PDP_TEXT_WITH_IMAGE: 47,
        TB_PDP_TEXT_WITHOUT_IMAGE: 48,
        PAGE_SECTION_WITHOUT_ITEMS: 18,
        CONTENT_AREA: 52,
        MOB_TABBED_PRODUCTS: 52,
        CAROUSEL: 1,
        BANNERS_HORIZONTAL_LIST: 3,
        TOP_CATEGORIES: 15,
        REFER_BENNER: 3,
        DID_YOU_KNOW: 52,
        MY_REFRAL:52,
        LOYALTY_BENEFIT: 1,


        // Home page
        HOME_PAGE: {
            FIR_SE_ZIDD_KAR: 18,
            SHOP_BY_FLAVOURS: 15,
            LAST_SECTION: 14,
            AUTHENTICATE: 18,
            BEST_SELLING: 16,
            TRENDING: 16,
            MBFeatureBanner: 52,
            ReOrder: 18,
        },
        PLP: {
            ReOrder: 18,
        }
    },
    PAGE_SECTION_DESIGN_TYPE: {
        ACCORDIAN_WITH_IMAGE: 28,
        IMAGE_WITH_TEXT_PARALLAX: 29,
        ACCORDIAN_WITHOUT_BOX: 30,
        IMAGE_WITH_TEXT_LIST: 31,
        IMAGE_WITH_TEXT_PARALLAX_VIEW_MORE: 44,
        ACCORDIAN_WITH_BOX: 45,
        TBS_INGREDIENT_BENEFIT_PAGE_SECTON: 50,
        TBS_INGREDIENT_PRODUCT_PAGE_SECTON: 48,
        CUSTOMER_REVIEW_PAGE_SECTION: 53,
        TABS_WITH_CAROUSEL: 77,
        MB_HM_PRODUCTS_BOTTOM: 78,
        VIDEO_SECTION: 17,
        DEFAULT: 1,
        FAQ_SECTION: 74,
        SHOP_BY_GOAL: 2,
        TESTIMONIAL: 7,
        INSTAGRAM_FEED: 16,
        CATEGORY_COMPOSITION_SECTION: 76,
        STORE: 8,
        SHOP_BY_LEVEL: 4,
        TOP_CATEGORIES: 36,
        REFER_BENNER:1,
        MY_REFRAL:28,
        DID_YOU_KNOW: 1,
        LOYALTY_BENEFIT: 3,
        LOYALTY_BENEFIT_PREM: 33,

        // Home Page
        HOME_PAGE: {
            FIR_SE_ZIDD_KAR: 35,
            SHOP_BY_FLAVOURS: 68,
            SHOP_BY_BRAND: 61,
            // LAST_SECTION: 1,
            AUTHENTICATE: 14,
            BEST_SELLING: 46,
            TRENDING: 73,
            POPULAR: 82,
            POPULAR_CAT: 32,
            POPULAR_PROD: 2,
            MBFeatureBanner: 52,
            ReOrder: 36,
        },
        PLP: {
            ReOrder: 36,
        }
    },
    personalizedWidgets: {
        personalized: 'personalized',
        timeToRefill: 'refill',
        cartWidget: 'cartWidget',
        crossProductWidget: 'crossProductWidget',
        type: {
            cartWidget: 1,
            crossProductWidget: 2,
            timeToRefill: 3
        }
    },
    eventNames: {
        PAYMENT_FAILURE_POPUP_COD: "PAYMENT_FAILURE_POPUP_COD",
        PAYMENT_FAILURE_POPUP_RETRY: "PAYMENT_FAILURE_POPUP_RETRY",
        PRODUCT_PAGE_SCROLL: "PRODUCT_PAGE_SCROLL",
        PRODUCT_IMAGE_VIEWED: "PRODUCT_IMAGE_VIEWED",
        PRODUCT_REVIEW_READ: "PRODUCT_REVIEW_READ",
        PRODUCT_ABOUT_TAB_CLICK: "PRODUCT_ABOUT_TAB_CLICK",
        PRODUCT_VIEW_OFFER: "PRODUCT_VIEW_OFFER",
        PRODUCT_NOTIFY_ME: "PRODUCT_NOTIFY_ME",
        PRODUCT_WISHLIST: "PRODUCT_WISHLIST",
        CATEGORY_PAGE_SCROLL: "CATEGORY_PAGE_SCROLL",
        SCREEN_VIEW: "SCREEN_VIEW",
        PDP_Know_click: "PDP_Know_click",
        PDP_Retail_click: "PDP_Retail_click",
        Retail_store_poPup_callClick: "callClick",
        Retail_store_poPup_directionClick: "directionClick",
        Know_more_load: "Know_more_load",
        know_more_Find_nearest_store: "know_more_Find_nearest_store",
        PDP_compare_click: "PDP_compare_click",
        Compare_search: "Compare_search",
        Compare_now: "Compare_now",
        Compare_clear: "Compare_clear",
        Compare_result_buy: "Compare_result_buy",
        PAYMENT_RETRY_AUTOLOGIN: "PAYMENT_RETRY_AUTOLOGIN",
        PAYMENT_RETRY_POP_VIEWED: 'PAYMENT_RETRY_POP_VIEWED',
        APPLY_FILTER: "APPLY_FILTER",
        RESET_FILTER: "RESET_FILTER",
        APPLY_SORT: "APPLY_SORT",
        PLP_COMPARE_CLICK: "PLP_compare_click",
        PLP_KNOW_CLICK: "PLP_Know_click",
        GAINWITHMB_VIDEOCLICK: "GainwithMb_videoclick",
        PAYMENT_PAGE_DROP_DOWN: 'DROP-DOWN-',
        SEARCH_ICON: 'SEARCH_ICON',
        SEARCH_TEXT_BAR: 'SEARCH_TEXT_BAR',
        PRODUCT_LIST_VIEW: 'PRODUCT_LIST_VIEW',
        PRODUCT_CLICK: 'PRODUCT_CLICK',
        RECENT_SEARCHES: 'RECENT_SEARCHES',
        BRANDS_AND_CATEGORIES: 'BRAND_AND_CATEGORIES',
        SUGGESTED_PRODUCTS: 'SUGGESTED_PRODUCTS',
        APPLY_SORT_CLICK: 'APPLY_SORT_CLICK',
        SEARCH_INPUT_QTY: 'SEARCH_INPUT_QTY',
        DELETE_USER:'delete_user',
    },
    searchActions: {
        recentsearches: 'recentsearches',
        brandsandcategories: 'brandsandcategories',
        suggestedproducts: 'suggestedproducts',
        enter: 'enter'
    },
    filterKeys: {
        morefilters: 'morefilters',
        offers: 'offers',
        discount: 'discount',
        pricerange: 'pricerange',
        rating: 'rating',
        categories: 'categories',
        goals: 'goals',
        stock_availability: 'stock_availability'
    },
    eventName: {
        PAGE_LOAD: 'PAGE_LOAD',
        COMPONENT_CLICK: 'COMPONENT_CLICK',
        COMPONENT_VIEW: 'COMPONENT_VIEW',
        ERROR: 'ERROR',
        COMPONENT_FOCUS: 'COMPONENT_FOCUS',
        PAGE_UNLOAD: 'PAGE_UNLOAD',
        COMPONENT_PRESS_ENTER: 'COMPONENT_PRESS_ENTER',
        ADD_TO_WISHLIST: 'Add_To_Wishlist',
        ADD_TO_CART: 'Add_To_Cart',
        GO_tO_CART: 'Go_to_Cart'
    },
    compareMessage: {
        CAT_NOT_MATCHED: "cat_not_matched",
        MAX_LIMIT_REACHED: "max_limit_reached",
        PRODUCT_ADDED: "product_added"
    },
    compareMaxItemsLimit: 10,
    ERROR_MESSAGE: {
        LOGIN_USER_DOES_NOT_EXIST: "You are not registered with us. Please sign up.",
        OTP_INVALID: "OTP is not valid",
    },
    ORDER_STATUS: {
        CANCELLED: "Cancelled",
        DELIVERED: "Delivered",
        PLACED: "Placed"
    },
    CertifiedId: "NUT5282-01,NUT5282-02,NUT5282-03,NUT5282-05,NUT4919-02,NUT4919-01,NUT1367-35, NUT1367-34,NUT1367-36,NUT1367-33,NUT1367-39,NUT1367-32,NUT1367-41,NUT1367-30,NUT5016-03,NUT5016-02,NUT5016-04,NUT5016-01,NUT4081-03,NUT2654-20,NUT2654-21,NUT2654-13,NUT5183-02,NUT5183-01,NUT4695-02, NUT4695-01,NUT5356-02,NUT2655-14,NUT2655-15,NUT5358-02,NUT5358-01,NUT5357-01,NUT5357-02,NUT1367-42, NUT1367-43,NUT5356-01,NUT5705-01,NUT5705-02,NUT5357-03,NUT5357-04,NUT5705-01,NUT5705-02,NUT5706-01,NUT5706-02,NUT4647-01,NUT4647-02, NUT5282-06,NUT5357-05,NUT5356-03,NUT5357-07,NUT5282-08,NUT5282-09,NUT5705-03,NUT5705-04,NUT5358-03,NUT5358-04, NUT5282-10,NUT5282-11,NUT4919-03,NUT5705-02,NUT5282-04,NUT1367-40,NUT5872-01,NUT5872-02,NUT1367-44,NUT1367-45,NUT5356-09,NUT5356-10,NUT5356-11,NUT5706-03,NUT5706-04,NUT5706-05,NUT5357-09,NUT5357-10,NUT6022-01,NUT6022-02,NUT5356-05,NUT5356-06,NUT5356-07,NUT5282-12,NUT5282-13",
    WORKOUT_GOAL_MAPPING: {
        1: 'Weight Loss',
        2: 'Weight Gain',
        3: 'Weight Maintain',
        4: 'Lean Muscle Mass',
        5: 'Bulk Gain',
    },
    WORKOUT_FREQUENCY_MAPPING: {
        1: '2 times',
        2: '3-4 times',
        3: '5 times and more',
    },
    WORKOUT_TENURE_MAPPING: {
        1: 'Less than 1 year',
        2: '1 to 3 years',
        3: 'More than 3 years',
    },
    paymentGrpIds: {
        DEBIT_CARD: 1,
        UPI: 64, //64
        WALLET: 153,
        INTERNET_BANKING: 4,
        EMI: 149,
        AMAZON_PAY: 6,
        PHONE_PE: 7,
        PAY_ON_DELIVERY: 8,
        CASH_ON_DELIVERY: 9,
        OTHER_WALLET: 10,
        PAYTM_WALLET: 11,
        SAVED_CARDS: 12, //81
        SAVED_CARDS_MWEB: 147, //62
        CREDIT_CARD: 13,
        DEBIT_CREDIT_CARD: 157,
        PAY_USING_UPI_INTENT: 2, //60, //25, //47 //2
        PAY_USING_UPI_INTENT_MWEB: 177,
        UPI_INTENT_ID: 2000,
        PAY_AT_STORE: 31,
        DEBIT_CARD_MWEB: 66, //45,//56, //32,
        CREDIT_CARD_MWEB: 68, //46,//, //33,
        RAZOR_PAY_UPI: 2,
        RAZOR_PAY_UPI_MWEB: 25, //25
        CRED: 159,
        DC_CC_CARD: 157,
        PAY_LATER: 87,
        SIMPL: 161
    },
    pendingPayment: {
        THANKYOU_PAGE_PENDING_PAYMENT: 14,
        PENDING_PAYMENT: 1,
        PAYMENT_FAILED: 2,
        PAYMENT_SUCCESS: 3,
        PAYMENT_REFUND: 4,
        PAYMENT_CANCELLED: 5
    },
    RAZORPAY_GID: '1502',
    PHONEPE: 'phonepe',
    checkoutStepsADD: [
        {
            tagSlug: "cart",
            tagName: "Cart"
        },
        {
            tagSlug: "address",
            tagName: "Add address"
        },
        {
            tagSlug: "payment",
            tagName: "Payment"
        },
    ],
    checkoutStepsADDBuy: [
        {
            tagSlug: "address",
            tagName: "Delivery Address"
        },
        {
            tagSlug: "payment",
            tagName: "Payment"
        },
        // {
        //     tagSlug: "purchase",
        //     tagName: "Purchase Summary"
        // }
    ],
    reviewStep: {
        tagSlug: "reviewOrder",
        tagName: "Order Preview"
    },
    varTag: {
        labdoor: {
            imgSrc: '/logos/des_labdors.png'
        },
        vegan: {
            imgSrc: '/logos/vegan.png'
        },
        nutra: {
            imgSrc: '/logos/des_nutras.png'
        },
        informed: {
            imgSrc: '/logos/des_informeds.png'
        },
        "asian star": {
            imgSrc: '/logos/des_asians.png'
        },
        sgs: {
            imgSrc: '/logos/des_sgs.png'
        },
        "u.s. patent filed": {
            imgSrc: '/logos/des_patent.png'
        }
    },
    MwebvarTag: {
        labdoor: {
            imgSrc: '/logos/mwe_labdor.png'
        },
        vegan: {
            imgSrc: '/logos/vegan.png'
        },
        nutra: {
            imgSrc: '/logos/mwe_nutra.png'
        },
        informed: {
            imgSrc: '/logos/mwe_informed.png'
        },
        "asian star": {
            imgSrc: '/logos/mwe_asian.png'
        },
        sgs: {
            imgSrc: '/logos/mwe_sgs.png'
        },
        "u.s. patent filed": {
            imgSrc: '/logos/mwe_patent.png'
        }
    },
    utm_source: {
        bajajpwa: 'bajajpwa'
    }
}
export default config;